<template>
    <div>
        <router-link class="no-decoration" :to="{name: 'my-page/talk-room/:id', params: {id: item.id }}">
            <div class="card">
                <div class="card-header">
                    <div class="info" v-if="item.lesson.level">
                        {{ $t(`lesson.${item.lesson.level}`)}}
                    </div>
                    <h5 class="card-title">
                       {{ $t('talk_room.lesson')}}: {{item.lesson.title}}
                    </h5>
                </div>
                <div class="card-body" id="table">
                    <div class="tr">
                            <div class="td">
                                <div class="cimg">
                                    <img 
                                        :src="item.groupProfile.profile_image" 
                                        :alt="item.groupProfile.name"/>
                                </div>
                            </div>
                            <div class="td w-100 descr">
                                <div class="d-flex justify-content-between">
                                    <h5 class="card-title">{{item.groupProfile.name}} </h5>
                                    {{ formatDateInstance(item.sent_at, "YYYY/MM/DD HH:mm") }}
                                </div>
                                <div class="card-text ellipse-text line--3">
                                    {{ item.content }}
                                </div>
                                <div class="card-text">
                                    <div class="count-inquiry pull-right">
                                        未読: {{item.unseen}}件
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </router-link>
    </div>
</template>
<script>
export default {
    name: "Item",
    props: {
        item: {
            type: Object,
            default: () => {}
        }
    }
}
</script>